<!--  -->
<template>
  <div>
    <div class="block">
  <span class="demonstration">商品批号</span>
  <el-cascader
  :placeholder="item"
    v-model="value"
    :options="options"
    @change="handleChange"></el-cascader>
</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: [],
      item:"请选择批号",
        options: [
        {
          value: 'ziyuan2',
          label: 'PAHDGYujsi234',
      
        },
        {
          value: 'ziyuan1',
          label: 'HDI43958945',
      
        },
         {
          value: 'ziyuan',
          label: 'HIK2949',
      
        }
      ]
    }
  },

  components: {},

  computed: {},

  methods: {
    handleChange(value) {
        console.log(value);
      }
  }
}

</script>
<style lang='less' scoped>
</style>